/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bcd2873a-aec9-4c76-8465-53d1552f3c23",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VwrFORj5M",
    "aws_user_pools_web_client_id": "31mkae5h9ilrq92emoqq0e5qhu",
    "oauth": {},
    "aws_user_files_s3_bucket": "tangerine-app-prod-storage20190903202707-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://3t6yfzlso5bs3mdfnhiznyomzi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2opee2aknjdshk75y4vv237ipe",
    "aws_content_delivery_bucket": "tangerine-app-20191007163220-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3orz6atl0pkjd.cloudfront.net"
};


export default awsmobile;
