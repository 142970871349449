import React from 'react';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
/*
import {
  StripeProvider,
} from 'react-stripe-elements'; */
//  import logo from './logo.svg';
import ErrorBoundary from './ErrorBoundary';
import Navigation from './Navigation/Navigation';
import CacheBuster from './CacheBuster';
import awsmobile from './aws-exports';
import './App.css';

Amplify.configure(awsmobile);

function App() {
  console.log(`name and version: ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
  return (
    <div className="App">
      <ErrorBoundary>
        <CacheBuster>
          <Router>
            <Navigation />
          </Router>
        </CacheBuster>
      </ErrorBoundary>
    </div>
  );
}

export default App;
