import React from 'react';
import {
  Route, Link,
} from 'react-router-dom';
import { Switch } from 'react-router';
import { Dimmer, Loader } from 'semantic-ui-react';
import loadable from '@loadable/component';

import './Navigation.scss';


const Loading = () => (
  <Loader active>
    <Dimmer />
  </Loader>
);

const VPCLearnMore = loadable(() => import('../About/VPCLearnMore'))

const UrgentCareLearnMore = loadable(() => import('../About/UrgentCareLearnMore'))

const Home = loadable(() => import('../Home/Home'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const HowDoesItWork = loadable(() => import('../About/HowDoesItWork'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const AppNavigation = loadable(() => import('../App/Navigation/AppNavigation'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

// const NPNavigation = loadable(() => import('../App/NonProfit/NPNavigation'), {
//   fallback: Loading({
//     pastDelay: true,
//     error: false,
//     timedOut: false,
//   }),
// });

const Footer = loadable(() => import('../About/Footer'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

// const Faq = loadable(() => import('../About/Faq'), {
//   fallback: Loading({
//     pastDelay: true,
//     error: false,
//     timedOut: false,
//   }),
// });

const Privacy = loadable(() => import('../About/Privacy'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Terms = loadable(() => import('../About/Terms'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Abscess = loadable(() => import('../About/Complaint/Abscess'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const AcidReflux = loadable(() => import('../About/Complaint/AcidReflux'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const BirthControl = loadable(() => import('../About/Complaint/BirthControl'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const ColdSore = loadable(() => import('../About/Complaint/ColdSore'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Conjunctivitis = loadable(() => import('../About/Complaint/Conjunctivitis'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Cough = loadable(() => import('../About/Complaint/Cough'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Diarrhea = loadable(() => import('../About/Complaint/Diarrhea'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const EarPain = loadable(() => import('../About/Complaint/EarPain'));

const ErectileDysfunction = loadable(() => import('../About/Complaint/ErectileDysfunction'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const ExternalHemorrhoids = loadable(() => import('../About/Complaint/ExternalHemorrhoids'));

const Flu = loadable(() => import('../About/Complaint/Flu'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Gout = loadable(() => import('../About/Complaint/Gout'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const MalariaProphylaxis = loadable(() => import('../About/Complaint/MalariaProphylaxis'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const MalePatternBaldness = loadable(() => import('../About/Complaint/MalePatternBaldness'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Mastitis = loadable(() => import('../About/Complaint/Mastitis'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const MedicalAdvice = loadable(() => import('../About/Complaint/MedicalAdvice'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const MedicationRenewal = loadable(() => import('../About/Complaint/MedicationRenewal'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Rash = loadable(() => import('../About/Complaint/Rash'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const SeasonalAllergies = loadable(() => import('../About/Complaint/SeasonalAllergies'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Shingles = loadable(() => import('../About/Complaint/Shingles'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const SinusInfection = loadable(() => import('../About/Complaint/SinusInfection'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Sorethroat = loadable(() => import('../About/Complaint/Sorethroat'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const StopSmoking = loadable(() => import('../About/Complaint/StopSmoking'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Toothache = loadable(() => import('../About/Complaint/Toothache'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Uti = loadable(() => import('../About/Complaint/Uti'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const VaginalDischarge = loadable(() => import('../About/Complaint/VaginalDischarge'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const Acne = loadable(() => import('../About/Complaint/Acne'), {
  fallback: Loading({
    pastDelay: true,
    error: false,
    timedOut: false,
  }),
});

const TestMic = loadable(() => import('../App/WaitingRoom/TestMic'));

const TestCamera = loadable(() => import('../App/WaitingRoom/TestCamera'));

const PublicNavigation = () => (
  <div>
    <nav
      className="dt w-100 pl2 pb2"
    >
      <Link className="dtc v-mid mid-gray link dim w-25" to="/" href title="Home">
        <img src={`${process.env.PUBLIC_URL}/images/tangerine.svg`} className="w-100 fl" alt="Tangerine Health" />
      </Link>
      <div className="dtc v-mid w-75 tr">
        <Link className="br2 link dim white hover-white bg-red f3-l f4-ns dib pl3 pr3 pv2 mr3 mr4-ns" to="/app/login" href title="Login">Login</Link>
        <Link className="br2 link dim white hover-white bg-blue f4-l f5-ns dib pl3 pr3 pv2 mr3 mr4-ns" to="/app/register" href title="Login">Register</Link>
      </div>
    </nav>
    <Switch>
      <Route exact path="/" render={props => <Home {...props} />} />
      <Route path="/test-mic" render={props => <TestMic {...props} />} />
      <Route path="/test-camera" render={props => <TestCamera {...props} />} />
      <Route path="/privacy" render={props => <Privacy {...props} />} />
      <Route path="/terms" render={props => <Terms {...props} />} />
      <Route path="/uti" render={props => <Uti {...props} />} />
      <Route path="/abscess" render={props => <Abscess {...props} />} />
      <Route path="/acid-reflux" render={props => <AcidReflux {...props} />} />
      <Route path="/birth-control" render={props => <BirthControl {...props} />} />
      <Route path="/cold-sore" render={props => <ColdSore {...props} />} />
      <Route path="/conjunctivitis" render={props => <Conjunctivitis {...props} />} />
      <Route path="/cough" render={props => <Cough {...props} />} />
      <Route path="/diarrhea" render={props => <Diarrhea {...props} />} />
      <Route path="/ear-pain">
        <EarPain />
      </Route>
      <Route path="/erectile-dysfunction" render={props => <ErectileDysfunction {...props} />} />
      <Route path="/external-hemorrhoids">
        <ExternalHemorrhoids />
      </Route>
      <Route path="/flu" render={props => <Flu {...props} />} />
      <Route path="/gout-attack" render={props => <Gout {...props} />} />
      <Route path="/malaria-prophylaxis" render={props => <MalariaProphylaxis {...props} />} />
      <Route path="/male-pattern-baldness" render={props => <MalePatternBaldness {...props} />} />
      <Route path="/mastitis" render={props => <Mastitis {...props} />} />
      <Route path="/medical-advice" render={props => <MedicalAdvice {...props} />} />
      <Route path="/medication-renewal" render={props => <MedicationRenewal {...props} />} />
      <Route path="/rash" render={props => <Rash {...props} />} />
      <Route path="/seasonal-allergies" render={props => <SeasonalAllergies {...props} />} />
      <Route path="/shingles" render={props => <Shingles {...props} />} />
      <Route path="/sinus-infection" render={props => <SinusInfection {...props} />} />
      <Route path="/sore-throat" render={props => <Sorethroat {...props} />} />
      <Route path="/stop-smoking" render={props => <StopSmoking {...props} />} />
      <Route path="/toothache" render={props => <Toothache {...props} />} />
      <Route path="/vaginal-discharge" render={props => <VaginalDischarge {...props} />} />
      <Route path="/acne" render={props => <Acne {...props} />} />
      <Route path="/urgent-care/learn-more">
        <UrgentCareLearnMore />
      </Route>
      <Route pathe="/vpc/learn-more">
        <VPCLearnMore />
      </Route>
    </Switch>
    <Footer />
  </div>
);


const Navigation = () => (
  <div>
    <Switch>
      <Route
        path="/app"
        render={(props =>
          (
            <AppNavigation
              {...props}
            />
          )
        )}
      />
      {/*
        <Route
                path="/non-profit"
                render={(props =>
                  (
                    <NPNavigation
                      {...props}
                    />
                  )
                )}
              /> */

        }
      <Route path="/" component={PublicNavigation} />
    </Switch>
  </div>
);

export default Navigation;
