import React, { useState, useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const Loading = () => (
  <Loader active>
    <Dimmer />
  </Loader>
);


// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

function CacheBuster({ children }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function refreshCacheAndReload() {
      console.log('Clearing cache and hard reloading...');
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
          // for (const name of names) caches.delete(name);
        });
      }

      // delete browser cache and hard reload
      window.location.reload(true);
    }
    const checkShouldForceRefresh = async () => {
      fetch('/meta.json')
        .then(res => res.json())
        .then((meta) => {
          const latestVersion = meta.version;
          const currentVersion = process.env.REACT_APP_VERSION;
          console.log(currentVersion, ' this is current version');
          console.log(latestVersion, ' this is latest version');
          const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
          if (shouldForceRefresh) {
            console.log(`We have a new version - ${latestVersion}. Should force refresh`);
            setLoading(false);
            refreshCacheAndReload();
          } else {
            console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
            setLoading(false);
          }
        });
    };

    checkShouldForceRefresh();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      {children}
    </div>
  );
}

export default CacheBuster;
