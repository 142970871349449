import React from 'react';
import ReactDOM from 'react-dom';
//  import 'semantic-ui-css/semantic.min.css';
//  import './custom.scss';
import './index.css';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  console.log = () => null;
  console.error = () => null;
  console.warn = () => null;
}

Sentry.init({ dsn: 'https://265104bbe74c46159cd162e22d8998cf@sentry.io/3720085' });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
